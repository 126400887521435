function debounce(debouncedFunction, waitTimeout, executeImmediately) {
  let timeout;

  return function() {
    const context = this;
    const args = arguments;

    const later = () => {
      timeout = null;

      if (!executeImmediately) {
        debouncedFunction.apply(context, args);
      }
    };

    const callNow = (executeImmediately && !timeout);

    clearTimeout(timeout);

    timeout = setTimeout(later, waitTimeout);

    if (callNow) {
      debouncedFunction.apply(context, args);
    }
  };
};

export default debounce;
