require('./pages/header');

require('./pages/signup_wizard');
require('./pages/letter_editor');
require('./pages/sms_editor');
require('./pages/content_editor');
require('./pages/debt_wizard');
require('./pages/edit_debtor');
require('./pages/submit_payment');
require('./pages/card_payment');

// Remove the `data-vue` attributes from any elements that have it
// as all vue portions should be loaded by now
const contentReady = require('./helpers/content_ready.js').default;
const filter = require('./components/filter.js').default;
const electronicSignature = require('./components/signature.js').default;

contentReady(() => {
  document.querySelectorAll('[data-vue]').forEach((element) => {
    element.removeAttribute('data-vue');
  });

  document.querySelectorAll('[data-vue-off]').forEach((element) => {
    element.parentNode.removeChild(element);
  });

  filter.init();
  electronicSignature.init();
});
