const BaseDTO = require('./base').default;

class ContactAttemptDTO extends BaseDTO {
  toJSON() {
    return {
      "date": this.subject.contactDate,
      "type": this.subject.contactType,
      "notes": this.subject.notes,
    };
  }
};

export default ContactAttemptDTO;
