import Vue from 'vue/dist/vue.esm.js';

Vue.component('wizard', {
  template: '#wizard-template',

  props: {
    previousStepLabel: { default: 'Previous' },
    nextStepLabel: { default: 'Next' },
    finalStepLabel: { default: 'Complete' },
    onNext: null,
    onBack: null,
    onStepUpdate: null,
    nextHidden: false,
    size: { default: 'medium' },
    gaps: { default: true },
  },

  watch: {
    steps: {
      handler() {
        this.parseOptions();
      },
      immediate: true,
    },
    currentStep: {
      handler() {
        if (typeof this.onStepUpdate === 'function') {
          this.onStepUpdate(this.currentSlot);
        }
      },
      immediate: true,
    },
  },

  data () {
    var stepDefinition = [];
    var currentStep = 0;

    var stepKeys = Object.keys(this.$slots);

    for(var i = 0; i < stepKeys.length; i++) {
      var stepObject = this.$slots[stepKeys[i]][0];

      stepDefinition.push({
        slot: stepObject.data.slot,
        label: stepObject.data.attrs.label,
        icon: stepObject.data.attrs.icon,
        modifiers: stepObject.data.attrs.modifiers,
        backDisabled: !!stepObject.data.attrs.backdisabled,
      });

      if (stepObject.data.attrs.initialstep) {
        currentStep = i;
      }
    }

    if (typeof this.onStepUpdate === 'function') {
      this.onStepUpdate(this.currentSlot);
    }

    return {
      steps: stepDefinition,
      currentStep: currentStep,
      isProcessing: false,
      isAwaitingPromise: false,
      options: [],
    };
  },

  computed: {
    currentSlot() {
      return this.steps[this.currentStep].slot;
    },

    wizardSize() {
      return 'is-' + this.size;
    },

    backEnabled() {
      return !this.isProcessing && this.currentStep != 0 && !this.steps[this.currentStep].backDisabled;
    },

    nextProcessing() {
      return !!this.isProcessing;
    },

    nextStepButtonLabel() {
      return this.currentStep == this.steps.length - 1 ? this.finalStepLabel : this.nextStepLabel;
    },
  },

  methods: {
    executeDelayPromise(duration) {
      duration = duration || 200;

      var promise = new Promise(function(resolve, reject) {
        setTimeout(function() { resolve(duration) }, duration);
      });

      return this.executePromise(promise);
    },

    executePromise(promise) {
      var _self = this;
      _self.isAwaitingPromise = true;

      return promise.finally(function() {
        _self.isAwaitingPromise = false;
      });
    },

    goNext(skipFunction) {
      var _self = this;

      _self.executeDelayPromise(750).then(function() {
        if (!skipFunction && typeof _self.onNext == 'function'){
          if(!_self.onNext(_self.steps[_self.currentStep])) {
            return;
          }
        }

        if (_self.currentStep < _self.steps.length-1) {
          _self.currentStep++;

          _self.scrollToTop();
        }
        else {
          _self.isProcessing = true;
        }
      });
    },

    goBack(skipFunction) {
      var _self = this;

      _self.executeDelayPromise(425).then(function() {
        if (_self.currentStep == 0 || _self.steps[_self.currentStep].backDisabled) {
          return;
        }

        if (!skipFunction && typeof _self.onBack == 'function'){
          if(!_self.onBack(_self.steps[_self.currentStep])) {
            return;
          }
        }

        _self.currentStep--;

        _self.scrollToTop();
      });
    },

    goTo(step) {
      var _self = this;

      _self.executeDelayPromise(1500).then(function() {
        if (Number.isInteger(step)
          && step < _self.steps.length
          && step >= 0) {
          _self.currentStep = step;

        _self.scrollToTop();
        }
      });
    },

    parseOptions() {
      this.options = [];
      for(let i = 0; i < this.steps.length; i++) {
        this.options.push(this.steps[i].options ? this.steps[i].options : {});
      }
    },

    scrollToTop() {
      window.scrollBy(document.body.scrollLeft, this.$el.getBoundingClientRect().top);
    },
  },
});
