import Vue from 'vue/dist/vue.esm.js';

Vue.component('required-value', {
  props: {
    value: { default: null },
    size: { default: 'small' },
  },

  template: `
    <span v-if="value">
      {{ value }}
    </span>
    <span v-else :class="'is-' + size + ' has-text-danger'">
      <i class="fas fa-exclamation-triangle"></i>
      Required
    </span>
  `,
});
