class BaseModel {
  constructor(attributes) {
    const _self = this;
    const keyValuePairs = Object.entries(attributes || {});

    this._errorList = {};

    keyValuePairs.forEach((keyVal) => {
      const key = keyVal[0].replace(/^_?/, '');
      const value = keyVal[1];
      const initializerName = ['initialize', key.charAt(0).toUpperCase(), key.slice(1)].join('');

      if (['validate', 'errors'].indexOf(key) >= 0) {
        // reserved keys
      }
      else if (typeof _self[initializerName] == 'function') {
        _self[initializerName](value);
      }
      else if (key.slice(-4) == 'Date') {
        _self._initializeDateValue(key, value);
      }
      else {
        this[key] = value;
      }
    });

    this.correlationID = this.correlationID || Math.random().toString(36).substr(2, 9);
  }

  _initializeDateValue(key, value) {
    const trimmedValue = ('' + value).trim();

    this[key] = ['', 'null', 'Invalid Date'].indexOf(trimmedValue) >= 0 ? null : new Date(trimmedValue);
  }

  isEmpty() {
    return false;
  }

  validate() {
    const _self = this;
    const attributes = Array.prototype.slice.call(arguments);

    this._lastValidatedAt = new Date();

    if (attributes.length == 0) {
      this._errorList = {};
    }
    else {
      attributes.forEach((attr) => {
        _self._errorList[attr] = [];
      });
    }

    (this._validations || []).forEach((validation) => {
      if (attributes.length && attributes.indexOf(validation.attr) < 0) {
        return;
      }

      if (typeof validation.if === 'function' && !validation.if(_self)) {
        return;
      }

      if (typeof validation.unless === 'function' && validation.unless(_self)) {
        return;
      }

      const error = validation.callback(_self);

      if (error) {
        _self._errorList[validation.attr] = _self._errorList[validation.attr] || [];
        _self._errorList[validation.attr].push(error)
      }
    });

    const errorKeys = Object.keys(this._errorList);
    let errorCount = 0;

    errorKeys.forEach((key) => {
      if (attributes.length && attributes.indexOf(key) < 0) {
        return;
      }

      errorCount += this._errorList[key].length;
    })

    return errorCount == 0;
  }

  errors(attributeOrOptions, options) {
    const kvPairs = Object.entries(this._errorList);
    let errorList = [];
    let attribute = null;

    if (attributeOrOptions instanceof Object) {
      options = attributeOrOptions;
    }
    else {
      attribute = attributeOrOptions;
    }

    options = options || {};

    kvPairs.forEach((keyVal) => {
      const attr = keyVal[0];
      const attrErrors = keyVal[1];
      const attrName = this._getAttributeName(attr);

      if (attribute && attr != attribute) {
        return;
      }

      attrErrors.forEach((error) => {
        errorList.push(attrName + ' ' + error);
      });
    });

    if (options.string) {
      return errorList.length > 0 ? errorList.join("\n") : null;
    }
    else {
      return errorList;
    }
  }

  _getAttributeName(attribute) {
    let attrName = (this._attributeNames || {})[attribute];

    if (attrName) {
      return attrName;
    }

    attrName = attribute.replace( /([a-z])([A-Z])/g, "$1 $2" );
    attrName = attrName.replace(/^./, (match) => { return match.toUpperCase(); });

    return attrName;
  }
}

// Validation helpers
function valueLength(value) {
  if (typeof value === 'undefined' || value === null) {
    return 0;
  }
  else if (Array.isArray(value)) {
    return value.length;
  }
  else if (value instanceof Object) {
    if (typeof value.getDate === 'function') {
      return 1;
    }
    else {
      return Object.keys(value).length;
    }
  }
  else {
    return ('' + value).trim().length;
  }
}

function valuePresent(value) {
  return valueLength(value) > 0;
}

function valueIsPositive(value) {
  return parseFloat(value) > 0;
}

BaseModel.attributeName = function(attribute, name) {
  this.prototype._attributeNames = this.prototype._attributeNames || {};

  this.prototype._attributeNames[attribute] = name;
}

BaseModel.validation = function(attribute, options) {
  const ifCallback = typeof options.if === 'function' ? options.if : null;
  const unlessCallback = typeof options.unless === 'function' ? options.unless : null;
  let validationCallback = typeof options.callback === 'function' ? options.callback : null;
  let callbackList = [];

  if (validationCallback) {
    callbackList.push(validationCallback);
  }

  if (typeof options.presence !== 'undefined') {
    callbackList.push(function(model) {
      const isPresent = valuePresent(model[attribute]);

      if (isPresent == options.presence) {
        return;
      }

      if (options.presence) {
        return 'must be present.';
      }
      else {
        return 'must not be present.';
      }
    });
  }

  if (typeof options.positive !== 'undefined') {
    callbackList.push(function(model) {
      const isPositive = valueIsPositive(model[attribute]);

      if (isPositive == options.positive) {
        return;
      }

      if (options.positive) {
        return 'must be positive.';
      }
      else {
        return 'must not be positive.';
      }
    });
  }

  this.prototype._validations = this.prototype._validations || [];

  if (callbackList.length > 0) {
    callbackList.forEach((callback) => {
      this.prototype._validations.push({
        attr: attribute,
        callback: callback,
        if: ifCallback,
        unless: unlessCallback,
      });
    });
  }
}

BaseModel.hasMany = function(name, modelClass, _options) {
  const options = _options || {};

  const pluralName = name;
  const pluralNameUpper = pluralName.charAt(0).toUpperCase() + pluralName.slice(1);

  const singularName = options.singular || (name.charAt(name.length - 1) == 's' ? name.slice(0, -1) : name);
  const singularNameUpper = singularName.charAt(0).toUpperCase() + singularName.slice(1);

  Object.defineProperty(this.prototype, pluralName, {
    get: function() {
      return this[`_${pluralName}`];
    },
    set: function(records) {
      let modelRecords = [];

      records.forEach(function(record) {
        modelRecords.push(new modelClass(record));
      });

      this[`_${pluralName}`] = modelRecords;
    },
  });

  this.prototype[`new${singularNameUpper}`] = function(attributes) {
    this[pluralName].push(new modelClass(attributes));
    return false;
  };

  this.prototype[`remove${singularNameUpper}`] = function(index) {
    this[pluralName].splice(index, 1);
    return false;
  };
}

BaseModel.hasOne = function(name, modelClass, _options) {
  const options = _options || {};

  Object.defineProperty(this.prototype, name, {
    get: function() {
      return this[`_${name}`];
    },
    set: function(attributes) {
      this[`_${name}`] = new modelClass(attributes);
    },
  });
}

export default BaseModel;
