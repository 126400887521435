import Cleave from 'cleave.js';

const cleave = {
  name: 'cleave',
  bind(el, binding, vnode) {
    const input = el.querySelector('input');
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector('input');
    input._vCleave.destroy();
  },
};

const inputMasks = {
  creditCard: {
    creditCard: true,
  },
  numeral: {
    numeral: true,
  },
  abn: {
    delimiter: ' ',
    blocks: [2, 3, 3, 3],
    numericOnly: true,
  },
  acn: {
    delimiter: ' ',
    blocks: [3, 3, 3],
    numericOnly: true,
  },
};

const inputMasksMixin = {
  directives: { cleave },
  data() { return { inputMasks }; },
};

export { inputMasksMixin as default, cleave, inputMasks, inputMasksMixin };
