const BaseModel = require('./base').default;
const Debtor = require('./debtor').default;
const Invoice = require('./invoice').default;
const Payment = require('./payment').default;
const ContactAttempt = require('./contact_attempt').default

class Debt extends BaseModel {
  constructor(attributes) {
    super(attributes);

    this.debtor = this.debtor || {};
    this.invoices = this.invoices || [];
    this.payments = this.payments || [];
    this.contactAttempts = this.contactAttempts || [];
    this.supportingDocumentation = this.supportingDocumentation || [];

    this.invoices = this.invoices.filter(function(record) {
      return !!Object.keys(record).length;
    });

    this.payments = this.payments.filter(function(record) {
      return !!Object.keys(record).length;
    });

    this.contactAttempts = this.contactAttempts.filter(function(record) {
      return !!Object.keys(record).length;
    });

    if (this.invoices.length == 0) {
      this.invoices.push(new Invoice);
    }

    if (this.payments.length == 0) {
      this.payments.push(new Payment);
    }

    if (this.contactAttempts.length == 0) {
      this.contactAttempts.push(new ContactAttempt);
    }
  }

  toJSON() {
    let cloned = Object.assign({}, this);

    // Need to specifically save these details on serialization so they can be
    // repopulated in the wizzy
    cloned.supportingDocumentation = this.supportingDocumentation.map((file) => {
      return {
        name: file.name,
        type: file.type,
        size: file.size,
        documentID: file.documentID,
        location: file.location,
        upload: file.upload,
        uploading: file.uploading,
      };
    });

    return cloned;
  }
};

Debt.attributeName('agreedAtDate', 'Agreement Date');
Debt.attributeName('agreementTypeText', 'Agreement Type');

Debt.hasOne('debtor', Debtor);
Debt.hasMany('invoices', Invoice);
Debt.hasMany('payments', Payment);
Debt.hasMany('contactAttempts', ContactAttempt);

Debt.validation('agreedAtDate', { presence: true });
Debt.validation('agreementType', { presence: true });
Debt.validation('agreementTypeText', { presence: true, if: (model) => { return model.agreementType == 'other'; } });
// Debt.validation('supportingDocumentation', { presence: true }); # Not required atm

Debt.validation('invoices', { callback: (model) => {
  let invoiceCount = 0;
  let invalidInvoices = 0;

  model.invoices.forEach((invoice) => {
    if (invoice.isEmpty()) {
      return;
    }

    invoiceCount++;
    invalidInvoices += invoice.validate() ? 0 : 1;
  });

  if (invoiceCount == 0) {
    return 'must include at least one valid invoice.';
  }

  if (invalidInvoices > 0) {
    return 'supplied must have all of their details correctly filled out.'
  }
} });

Debt.validation('payments', { callback: (model) => {
  let paymentCount = 0;
  let invalidPayments = 0;

  model.payments.forEach((payment) => {
    if (payment.isEmpty()) {
      return;
    }

    paymentCount++;
    invalidPayments += payment.validate() ? 0 : 1;
  });

  if (invalidPayments > 0) {
    return 'supplied must have all of their details correctly filled out.'
  }
} });

Debt.validation('contactAttempts', { callback: (model) => {
  let contactCount = 0;
  let invalidContacts = 0;

  model.contactAttempts.forEach((contact) => {
    if (contact.isEmpty()) {
      return;
    }

    contactCount++;
    invalidContacts += contact.validate() ? 0 : 1;
  });

  // if (contactCount == 0) {
  //   return 'must include at least one contact attempt.';
  // }

  if (invalidContacts > 0) {
    return 'supplied must have all of their details correctly filled out.'
  }
} });


export default Debt;
