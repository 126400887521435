const formattersMixin = {
  methods: {
    formatDecimal(value) {
      if (value && typeof value.toLocaleString == 'function') {
        value = parseFloat(value.toString().replace(/[^0-9.]/g, ''));

        return value.toLocaleString('en-AU', { style: 'decimal' });
      }

      return '';
    },
    formatMoney(value) {
      if ((value || value === '0.0' || typeof value === 'number') && typeof value.toLocaleString == 'function') {
        value = parseFloat(value.toString().replace(/[^0-9.]/g, ''));

        return value.toLocaleString('en-AU', { style: 'currency', currency: 'AUD', currencyDisplay: 'symbol' });
      } else if (typeof value === 'number') {
        return value;
      }

      return '';
    },
    formatDate(value) {
      let formattedValue = '';

      if (value && typeof value.toLocaleDateString == 'function') {
        formattedValue = value.toLocaleDateString('en-AU');
      }

      if (formattedValue.toString() == 'Invalid Date') {
        formattedValue = '';
      }

      return formattedValue;
    },
    datePickerDisplay(value) {
      return value.getFullYear() + '-' + (value.getMonth() < 9 ? '0' : '') + (value.getMonth() + 1) + '-' + (value.getDate() < 10 ? '0' : '') + value.getDate();
    },
    datePickerParser(value) {
      const parts = ('' + value).trim().replace(/\-/g, '/').split('/');

      if (parts.length === 3) {
        let part1 = parseInt(parts[0], 10);
        let part2 = parseInt(parts[1], 10);
        let part3 = parseInt(parts[2], 10);

        let year;
        let month = part2 - 1;
        let day;

        if (part3 > 31) {
          year = part3;
          day = part1;
        }
        else {
          year = part1;
          day = part3;
        }

        if (year < 100) {
          year += 2000;
        }

        return new Date(year, month, day);
      }

      try {
        let parsed = new Date(value);

        return parsed;
      } catch(err) {
        // nothing
      }

      return null;
    },
  },
};

export default formattersMixin;
