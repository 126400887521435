const AES = require('crypto-js/aes');
const Utf8Enc = require('crypto-js/enc-utf8');

function loadFromLocalStorage(key, fallback, secretKey) {
  let result;

  try {
    let source = localStorage.getItem(key);

    if (!source) {
      result = fallback
    }
    else if (secretKey) {
      const data = AES.decrypt(source, secretKey);

      result = JSON.parse(data.toString(Utf8Enc));
    }
    else {
      result = JSON.parse(source)
    }
  }
  catch (e) {
    result = fallback;
  }

  return result;
}

function saveToLocalStorage(key, value, secretKey) {
  let storedValue;

  if (secretKey) {
    storedValue = AES.encrypt(JSON.stringify(value), secretKey).toString();
  }
  else {
    storedValue = JSON.stringify(value);
  }

  return localStorage.setItem(key, storedValue);
}

function clearFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export { loadFromLocalStorage, saveToLocalStorage, clearFromLocalStorage };
