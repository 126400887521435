import Vue from 'vue/dist/vue.esm.js';
import vue2Dropzone from 'vue2-dropzone';

const contentReady = require('../helpers/content_ready.js').default;
const localStorageHelpers = require('../helpers/local_storage');
const debounce = require('../helpers/debounce').default;
const triggerEvent = require('../helpers/trigger_event').default;
const isDevelopment = require('../helpers/development').default;

const Debt = require('../models/debt').default;
const DebtDTO = require('../dtos/debt').default;

contentReady(() => {
  let changesMade = false;

  if (!isDevelopment) {
    window.addEventListener('beforeunload', function (event) {
      if (!changesMade) { return false }

      event.preventDefault();
      event.returnValue = '';

      return true;
    });
  }

  const localStorageDebtKey = 'debtwizard.debt';

  const loadDebtFromLocalStorage = () => {
    let debt = new Debt(localStorageHelpers.loadFromLocalStorage(localStorageDebtKey, {}, window.DebtForce.clientSecretKey));

    return (debt);
  };

  const saveDebtToLocalStorage = (context) => {
    localStorageHelpers.saveToLocalStorage(localStorageDebtKey, context.debt, window.DebtForce.clientSecretKey);
  };

  const debouncedSaveDebtToLocalStorage = debounce(saveDebtToLocalStorage, 300);

  function submitDebt(vue) {
    const data = new DebtDTO(vue.debt);

    let request = new XMLHttpRequest();

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    if (typeof window.fbq === 'function') {
      window.fbq(
        'track',
        'Submit Application',
        {
          currency: 'AUD',
          value: vue.totalDebtAmount,
        },
      );
    }

    triggerEvent(
      'event',
      'conversion',
      {
        'send_to': 'AW-605221268/1uqFCNHX-9sBEJTjy6AC',
      },
    );

    request.open('POST', '/debt', true);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.setRequestHeader('X-CSRF-Token', csrfToken);

    request.onload = function() {
      // All good
      if (request.status >= 200 && request.status < 400) {
        const response = JSON.parse(request.responseText);

        vue.successScreen = true;
        vue.debt = new Debt();

        changesMade = false;

        localStorageHelpers.clearFromLocalStorage(localStorageDebtKey);
      }
      else {
        console.log('Submitting the debt...');
      }
    };

    // Connection error
    request.onerror = function() {
      console.log('Error!');
    };

    request.send(JSON.stringify(data));
  }

  function loadFees() {
    let fees = window.DebtForce.fees;

    fees = fees.sort(function(a, b) {
      return a.lower_bound - b.lower_bound;
    });

    return fees;
  }

  function loadPlan() {
    return window.DebtForce.plan;
  }

  if (document.getElementById('debt_wizard')) {
    const digitalSignatureValue = document.querySelector('[data-digital-signature-value]').innerText.toLowerCase().trim();

    new Vue({
      el: '#debt_wizard',
      components: { vueDropzone: vue2Dropzone },
      data: {
        debt: loadDebtFromLocalStorage(),
        digitalSignature: '',
        successScreen: false,
        fees: loadFees(),
        plan: loadPlan(),
        debtMinimumEnabled: true,
        dropzoneOptions: {
          url: '/document',
          destroyDropzone: false,
          thumbnailHeight: 120,
          thumbnailWidth: 180,
          addRemoveLinks: true,
        },
        currentStep: null,
      },
      watch: {
        debt: {
          handler() {
            if (this.debt !== new Debt() && !this.successScreen) {
              changesMade = true;
            }

            debouncedSaveDebtToLocalStorage(this);
          },
          deep: true,
        },
        'debt.invoices': {
          handler(invoices){
            let changeCount = 0;

            invoices.forEach(function(invoice) {
              if (invoice.invoiceDate && invoice.sentDate) { return; }
              if (!invoice.invoiceDate && !invoice.sentDate) { return; }

              invoice.invoiceDate = invoice.invoiceDate || invoice.sentDate;
              invoice.sentDate = invoice.sentDate || invoice.invoiceDate;

              changeCount++;
            });

            if (changeCount) {
              this.$forceUpdate();
            }
          },
          deep: true,
        },
        'debt.debtor.contacts': {
          handler(contacts){
            let hasEmail = false;
            let hasMobile = false;

            contacts.forEach(function(contact) {
              if (!contact.value || contact.value.trim().length < 1) {
                return;
              }

              if (contact.contactType == 'email') {
                hasEmail = true;
              }

              if (contact.contactType == 'mobile') {
                hasMobile = true;
              }
            });

            if (hasEmail && hasMobile) {
              this.debt.debtor.suggestionText = null;
            }
            else {
              this.debt.debtor.suggestionText = 'Including at least one email and one mobile contact method significantly increases the chance of collecting the debt.';
            }

            this.$forceUpdate();
          },
          deep: true,
        },
      },
      computed: {
        todaysDate() {
          return new Date();
        },
        totalInvoiceAmount() {
          const invoiceList = (this.debt.invoices || []);

          let totalInvoiceSum = 0;

          invoiceList.forEach(function(invoice) {
            const invoiceValue = (invoice.total ? parseFloat(invoice.total.replace(/,/g, '')) : 0);

            totalInvoiceSum += (invoiceValue > 0 ? invoiceValue : 0);
          });

          return totalInvoiceSum;
        },
        totalPaymentAmount() {
          if (this.debt.anyPaymentsMade != 'true') {
            return 0;
          }

          const paymentList = (this.debt.payments || []);

          let totalPaymentSum = 0;

          paymentList.forEach(function(payment) {
            const paymentValue = (payment.paymentAmount ? parseFloat(payment.paymentAmount.replace(/,/g, '')) : 0);

            totalPaymentSum += (paymentValue > 0 ? paymentValue : 0);
          });

          return totalPaymentSum;
        },
        totalAfterPayments() {
          let totalDebtSum = this.totalInvoiceAmount - this.totalPaymentAmount;

          totalDebtSum = totalDebtSum < 0 ? 0 : totalDebtSum;

          return totalDebtSum;
        },
        applicableFee() {
          return this.calculateApplicableFee(this.totalAfterPayments) || { lower_bound: 0, rate: 0, upper_bound: 0 };
        },
        nextHidden() {
          if (this.currentStep == 'creditor_details') {
            return false;
          }

          if (!this.isDebtMinimumEnabled) {
            return false;
          }

          if (this.debt.overDebtMinimum != 'true') {
            return true;
          }

          if (this.currentStep == 'review' && this.isDebtUnderMinimum) {
            return true;
          }

          return false;
        },
        isDebtUnderMinimum() {
          return (this.isDebtMinimumEnabled && this.totalDebtAmount < this.lowestFeeBound);
        },
        isDebtMinimumEnabled() {
          return (this.debtMinimumEnabled && this.lowestFeeBound > 0);
        },
        lowestFeeBound() {
          return this.scopedFees[0].lower_bound || 0;
        },
        totalRecoveryFee() {
          return this.totalAfterPayments * (this.applicableFee.rate / 100);
        },
        totalDebtAmount() {
          return this.totalAfterPayments + (this.debt.agreementDebtCollectionClausePresent ? this.totalRecoveryFee : 0);
        },
        totalReceivableAmount() {
          return this.totalDebtAmount - this.totalRecoveryFee;
        },
        scopedFees() {
          if (typeof this.debt.creditor === undefined || !this.debt.creditor) {
            return this.fees;
          }

          const standardFees = this.fees.filter((fee) => {
            return (typeof fee.creditor_id === undefined || !fee.creditor_id) && (typeof fee.business_type === undefined || !fee.business_type);
          });

          const creditorFees = this.fees.filter((fee) => {
            return fee.creditor_id == this.debt.creditor;
          });

          if (creditorFees.length) {
            return creditorFees;
          } else {
            return standardFees;
          }
        },
        isFreeSubmission() {
          if (!this.plan || typeof this.plan.fc_remaining === undefined) {
            return false;
          }

          return this.plan.fc_remaining > 0;
        },
      },
      methods: {
        restartDebtWizard() {
          changesMade = false;
          this.successScreen = false;
        },
        calculateApplicableFee(value) {
          let applicableFees = this.scopedFees.filter(function(fee) {
            return fee.lower_bound <= value;
          });

          let currentFee = applicableFees.slice(-1)[0];

          if (!currentFee) {
            return;
          }

          let upperFee = this.scopedFees[this.scopedFees.indexOf(currentFee) + 1];

          currentFee.upper_bound = (upperFee ? upperFee.lower_bound : 0);

          return currentFee;
        },
        errorOnFailedValidations(validations) {
          let totalCount = 0;
          let validCount = 0;

          validations.forEach((valid) => {
            totalCount++;
            validCount += (valid ? 1 : 0);
          });

          if (validCount != totalCount) {
            this.$snackbar.open({
              duration: 5000,
              message: 'Please correct any errors before continuing.',
              type: 'is-danger',
              position: 'is-bottom-right',
              queue: false,
            });

            this.$forceUpdate();

            return true;
          }

          return false;
        },
        stepUpdated(currentStep) {
          this.currentStep = currentStep;
        },
        nextClicked(currentPage) {
          if (currentPage.slot == 'creditor_details' && !this.debt.creditor) {
            this.$snackbar.open({
              duration: 5000,
              message: 'You must select a creditor.',
              type: 'is-danger',
              position: 'is-bottom-right',
              queue: false,
            });

            return false;
          }

          if (currentPage.slot == 'debtor_details') {
            if (this.isDebtMinimumEnabled && this.debt.overDebtMinimum != 'true') {
              this.$snackbar.open({
                duration: 10000,
                message: `Debts under ${this.formatMoney(this.scopedFees[0].lower_bound)} are not eligible for collection through Debt Force.`,
                type: 'is-danger',
                position: 'is-bottom-right',
                queue: false,
              });

              return false;
            }

            const validations = [
              this.debt.debtor.validate(),
              this.debt.debtor.address.validate(),
            ];

            if (this.errorOnFailedValidations(validations)) {
              return false;
            }
          }

          if (currentPage.slot == 'agreements') {
            const validations = [
              this.debt.validate(
                'agreedAtDate',
                'agreementType',
                'agreementTypeText',
                'supportingDocumentation',
              ),
            ];

            if (this.errorOnFailedValidations(validations)) {
              return false;
            }

            let uploadsInProgress = 0;

            this.debt.supportingDocumentation.forEach((file) => {
              uploadsInProgress += file.uploading ? 1 : 0;
            });

            if (uploadsInProgress) {
              this.debt.supportingDocumentation = this.uploadedDocumentation();

              // Probably let them click next again within a short timeframe to
              // automatically cancel all outstanding uploads. Something for later.
              this.$snackbar.open({
                duration: 5000,
                message: 'You still have uploads in progress. Please wait until they are complete to continue.',
                type: 'is-danger',
                position: 'is-bottom-right',
                queue: false,
              });

              return false;
            }
          }

          if (currentPage.slot == 'invoices') {
            let attributes = ['invoices'];

            if (this.debt.anyPaymentsMade == 'true') {
              attributes.push('payments');
            }

            if (this.debt.anyFollowupContact == 'true') {
              attributes.push('contactAttempts');
            }

            const validations = [
              this.debt.validate.apply(this.debt, attributes),
            ];

            if (this.errorOnFailedValidations(validations)) {
              return false;
            }
          }

          if (currentPage.slot == 'review') {
            if (this.digitalSignature.toLowerCase().trim() == digitalSignatureValue) {
              submitDebt(this);

              return true;
            }
            else {
              this.$snackbar.open({
                duration: 10000,
                message: 'You must confirm the debt by entering your full name, "' + digitalSignatureValue + '", to submit the debt.',
                type: 'is-danger',
                position: 'is-bottom-right',
                queue: false,
              });

              return false;
            }
          };

          return true;
        },
        backClicked(currentPage) {
          return true;
        },
        // Attachment specific
        dropzoneMounted() {
          this.debt.supportingDocumentation.forEach((file) => {
            // Skip anything that was still "uploading"
            if (file.uploading) return;
            if (!file.location) return;

            this.$refs.debtDropzone.manuallyAddFile(file, file.location);
          });

          this.debt.supportingDocumentation = this.uploadedDocumentation();
        },
        uploadedDocumentation() {
          return this.debt.supportingDocumentation.filter((file) => {
            return !file.uploading;
          });
        },
        debtFileSending(file, xhr, formData) {
          const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

          formData.append('creditor', this.debt.creditor);

          xhr.setRequestHeader('X-CSRF-Token', csrfToken);
        },
        debtFileAdded(file) {
          const previewElement = file.previewElement.querySelector('.dz-image img');
          const previewSource = this.determineFilePreviewSource(file);

          if (previewSource) {
            previewElement.src = previewSource;
          }

          if (!file.manuallyAdded) {
            file.location = null;
            file.uploading = true;
            this.debt.supportingDocumentation.push(file);
          }
        },
        debtFileThumbnailGenerated(file, dataUrl) {
          if (!file.thumbnailDataURL) {
            file.thumbnailDataURL = dataUrl;
          }
        },
        debtFileRemoved(file, error, xhr) {
          let filePosition = this.debt.supportingDocumentation.indexOf(file);

          if (filePosition >= 0) {
            this.debt.supportingDocumentation.splice(filePosition, 1);
          }
        },
        debtFileSuccess(file, response) {
          const documentLocation = file.xhr.getResponseHeader('Location');
          const documentID = file.xhr.getResponseHeader('X-Document-ID');

          file.documentID = documentID;
          file.location = documentLocation;
          file.uploading = false;

          this.$forceUpdate();
        },
        debtFileError(file, error, xhr) {
          // Probably need to do something for user... inline flash message?
          this.debtFileRemoved(file);
        },
        determineFilePreviewSource(file) {
          const ext = file.upload.filename.split('.').pop();

          if (file.thumbnailDataURL) {
            return file.thumbnailDataURL;
          } else if (ext == 'pdf') {
            return '/img/icons/pdf.png';
          } else if (['doc', 'docm', 'docx'].indexOf(ext) != -1) {
            return '/img/icons/word.png';
          } else if (['xls', 'xlsm', 'xlsx'].indexOf(ext) != -1) {
            return '/img/icons/excel.png';
          } else if (['png', 'jpg', 'jpeg', 'gif', 'tif', 'tiff'].indexOf(ext) != -1) {
            return '/img/icons/image.png';
          }
        },
      },
    });
  }
});
