const BaseModel = require('./base').default;

class ContactAttempt extends BaseModel {
  isEmpty() {
    return (!this.contactDate && !this.contactType);
  }
};

ContactAttempt.validation('contactDate', { presence: true });
ContactAttempt.validation('contactType', { presence: true });

export default ContactAttempt;
