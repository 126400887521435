const contentReady = require('../helpers/content_ready.js').default;

contentReady(() => {
  let cardNumber, cardExpiry, cardCvc;
  let form;
  let orderData = {
    items: [{ id: 'debt-repayment' }],
    currency: 'aud',
  };

  if (document.querySelector('[data-debt-payment="stripe"]')) {
    var post = function(options) {
      let request = new XMLHttpRequest();

      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

      request.open('POST', options.url, true);
      request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      request.setRequestHeader('X-CSRF-Token', csrfToken);

      request.onload = function() {
        if (options.success) {
          const response = JSON.parse(request.responseText);
          options.success(request, response);
        }
      };

      request.onerror = function() {
        if (options.error) {
          const response = JSON.parse(request.responseText);
          options.error(request, response);
        }
      };

      request.send(JSON.stringify(options.body));
    };

    var handleAction = function(clientSecret) {
      // Show the authentication modal if the PaymentIntent has a status of "requires_action"
      stripe.handleCardAction(clientSecret).then(function(data) {
        if (data.error) {
          showError('Your card was not authenticated, please try again');
        } else if (data.paymentIntent.status === 'requires_confirmation') {
          // Card was properly authenticated, we can attempt to confirm the payment again with the same PaymentIntent
          post({
            url: stripePayPath,
            body: { paymentIntentId: data.paymentIntent.id },
            success: function(_request, _response) {
              orderComplete(clientSecret);
            },
            error: function(_request, response) {
              showError(response.error);
            },
          });
        }
      });
    };

    /*
     * Collect card details and pay for the order
     */
    var pay = function() {
      var name = form.querySelector('#stripe-cc-name');
      var email = form.querySelector('#stripe-cc-email');
      var phone = form.querySelector('#stripe-cc-phone');
      var address1 = form.querySelector('#stripe-cc-address');
      var city = form.querySelector('#stripe-cc-city');
      var state = form.querySelector('#stripe-cc-state');
      var postcode = form.querySelector('#stripe-cc-postcode');

      var data = {};

      if (name || email || phone) {
        data.billing_details = data.billing_details || {
          name: name ? name.value : undefined,
          email: email ? email.value : undefined,
          phone: phone ? phone.value : undefined,
        };
      }

      if (address1 || city || state || postcode) {
        data.billing_details = data.billing_details || {};
        data.billing_details.address = {
          line1: address1 ? address1.value : undefined,
          city: city ? city.value : undefined,
          state: state ? state.value : undefined,
          postal_code: postcode ? postcode.value : undefined,
        };
      }

      // Collect card details
      stripe
        .createPaymentMethod('card', cardNumber, data)
        .then(function(result) {
          if (result.error) {
            showError(result.error.message);
          } else {
            orderData.paymentMethodId = result.paymentMethod.id;
            orderData.isSavingCard = true; // document.querySelector("#save-card").checked;

            post({
              url: stripePayPath,
              body: orderData,
              success: function(_request, paymentData) {
                if (paymentData.requiresAction) {
                  // Request authentication
                  console.log('require auth', paymentData.requiresAction);
                  handleAction(paymentData.clientSecret);
                } else if (paymentData.error) {
                  console.log('error', paymentData.error);
                  showError(paymentData.error);
                } else {
                  console.log('order complete', paymentData.clientSecret);
                  orderComplete(paymentData.clientSecret);
                }
              },
            });
          }
        })
    };

    /* ------- Post-payment helpers ------- */

    /* Shows a success / error message when the payment is complete */
    var orderComplete = function(clientSecret) {
      stripe.retrievePaymentIntent(clientSecret).then(function(result) {
        var paymentIntent = result.paymentIntent;

        const paymentContainers = document.querySelectorAll('[data-debt-payment]');
        const successContainers = document.querySelectorAll('[data-debt-payment-success]');

        paymentContainers.forEach(function(container) {
          container.classList.add('is-hidden');
        });

        successContainers.forEach(function(container) {
          console.log(container);
          container.classList.remove('is-hidden');
        });
      });
    };

    var showError = function(errorMsgText) {
      var error = document.querySelector('[data-payment-error]');
      var errorMsg = error.querySelector('span');

      error.classList.remove('is-hidden');
      errorMsg.textContent = errorMsgText;

      enableInputs();

      setTimeout(() => { error.classList.add('is-hidden') }, 4000);
    };

    function enableInputs() {
      document.querySelector('#stripe-cc').classList.remove('is-submitting');

      Array.prototype.forEach.call(
        form.querySelectorAll(
          "input[type='text'], input[type='email'], input[type='tel']"
        ),
        function(input) {
          input.removeAttribute('disabled');
        }
      );
    }

    function disableInputs() {
      document.querySelector('#stripe-cc').classList.add('is-submitting');

      Array.prototype.forEach.call(
        form.querySelectorAll(
          "input[type='text'], input[type='email'], input[type='tel']"
        ),
        function(input) {
          input.setAttribute('disabled', 'true');
        }
      );
    }

    function registerElements(elements, exampleName) {
      var formClass = '#' + exampleName;
      var example = document.querySelector(formClass);

      form = example.querySelector('form');

      function triggerBrowserValidation() {
        // The only way to trigger HTML5 form validation UI is to fake a user submit
        // event.
        var submit = document.createElement('input');
        submit.type = 'submit';
        submit.style.display = 'none';
        form.appendChild(submit);
        submit.click();
        submit.remove();
      }

      // Listen for errors from each Element, and show error messages in the UI.
      var savedErrors = {};
      elements.forEach(function(element, idx) {
        element.on('change', function(event) {
          if (event.error) {
            // error.classList.add('visible');
            savedErrors[idx] = event.error.message;
            // errorMessage.innerText = event.error.message;
          } else {
            savedErrors[idx] = null;

            // Loop over the saved errors and find the first one, if any.
            var nextError = Object.keys(savedErrors)
              .sort()
              .reduce(function(maybeFoundError, key) {
                return maybeFoundError || savedErrors[key];
              }, null);

            if (nextError) {
              // Now that they've fixed the current error, show another one.
              // errorMessage.innerText = nextError;
            } else {
              // The user fixed the last error; no more errors.
              // error.classList.remove('visible');
            }
          }
        });
      });

      // Listen on the form's 'submit' handler...
      form.addEventListener('submit', function(e) {
        e.preventDefault();

        // Trigger HTML5 validation UI on the form if any of the inputs fail
        // validation.
        var plainInputsValid = true;
        Array.prototype.forEach.call(form.querySelectorAll('input'), function(
          input
        ) {
          if (input.checkValidity && !input.checkValidity()) {
            plainInputsValid = false;
            return;
          }
        });

        if (!plainInputsValid) {
          triggerBrowserValidation();
          return;
        }

        disableInputs();

        pay();
      });
    };

    var setupElements = function() {
      // Floating labels
      var inputs = document.querySelectorAll('#stripe-cc .input');

      Array.prototype.forEach.call(inputs, function(input) {
        input.addEventListener('focus', function() {
          input.classList.add('is-focused');
        });
        input.addEventListener('blur', function() {
          input.classList.remove('is-focused');
        });
        input.addEventListener('keyup', function() {
          if (input.value.length === 0) {
            input.classList.add('is-empty');
          } else {
            input.classList.remove('is-empty');
          }
        });
      });

      var elementStyles = {
        base: {
          color: '#363636',
          fontWeight: 400,
          fontFamily: 'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontSize: '16px',
          '::placeholder': {
            color: '#c2c2c2',
          },
        },
      };

      var elementClasses = {
        focus: 'is-focused',
        empty: 'is-empty',
        invalid: 'is-invalid',
      };

      cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardNumber.mount('#stripe-cc-card-number');

      cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardExpiry.mount('#stripe-cc-card-expiry');

      cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      });
      cardCvc.mount('#stripe-cc-card-cvc');

      registerElements([cardNumber, cardExpiry, cardCvc], 'stripe-cc');
    };

    setupElements();
  }
});
