let generatorCache = {
  ids: [],
  names: [],
};

const generateAndPush = function(namespace, name) {
  if (generatorCache[namespace].indexOf(name) < 0) {
    generatorCache[namespace].push(name);
  }

  return name;
};

const getLast = function(namespace) {
  return generatorCache[namespace].slice(-1)[0];
};

function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}

const generatorsMixin = {
  methods: {
    generateID(attributePath) {
      const attributes = camelToUnderscore(attributePath).replace(/[^a-z0-9_.]/g, '').split('.');
      const name = attributes.join('_');

      return generateAndPush('ids', name);
    },
    lastGeneratedID() {
      return getLast('ids');
    },
    generateName(attributePath) {
      let attributes = attributePath.split('.');
      let namespace = attributes.shift();
      let name = camelToUnderscore(namespace);

      attributes.forEach((attr) => {
        name += `[${camelToUnderscore(attr)}]`;
      });

      return generateAndPush('names', name);
    },
    lastGeneratedName() {
      return getLast('names');
    },
  },
};

export default generatorsMixin;
