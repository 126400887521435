const BaseDTO = require('./base').default;

class InvoiceDTO extends BaseDTO {
  toJSON() {
    return {
      "correlation_id": this.subject.correlationID,
      "invoice_date": this.subject.invoiceDate,
      "invoice_number": this.subject.number,
      "sent_date": this.subject.sentDate,
      "total": this.subject.total.replace(/[^0-9.]/g, ''),
      "tax_inclusive": this.subject.taxInclusive,
    };
  }
};

export default InvoiceDTO;
