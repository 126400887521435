import Vue from 'vue/dist/vue.esm.js';

const contentReady = require('../helpers/content_ready.js').default;

function togglePrimaryNav() {
  const navBar = document.getElementById('primary-nav-bar');

  navBar.classList.toggle('display-nav');
}

contentReady(() => {
  if (document.getElementById('header')) {
    new Vue({ el: '#header' });
  }

  if (document.getElementById('primary-nav-bar')) {
    const burgerButton = document.querySelector("[data-target='primary-nav-bar']");

    burgerButton.addEventListener('click', togglePrimaryNav);
  }
});
