const BaseModel = require('./base').default;

class Invoice extends BaseModel {
  constructor(attributes) {
    super(attributes);

    if (typeof this.taxInclusive === 'undefined' || this.taxInclusive === null) {
      this.taxInclusive = true;
    }
  }

  isEmpty() {
    return (!this.invoiceDate && !this.sentDate && !this.number && !this.total);
  }
};

Invoice.attributeName('number', 'Invoice Number/Reference');
Invoice.attributeName('total', 'Invoice Amount');

Invoice.validation('invoiceDate', { presence: true });
Invoice.validation('sentDate', { presence: true });
Invoice.validation('number', { presence: true });
Invoice.validation('total', { presence: true, positive: true });

export default Invoice;
