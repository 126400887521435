const BaseDTO = require('./base').default;
const InvoiceDTO = require('./invoice').default;
const PaymentDTO = require('./payment').default;
const ContactAttemptDTO = require('./contact_attempt').default;
const DebtorDTO = require('./debtor').default;

class DebtDTO extends BaseDTO {
  invoiceSet() {
    const presentInvoices = this.subject.invoices.filter((record) => {
      return !record.isEmpty();
    });

    return presentInvoices.map((record) => {
      return new InvoiceDTO(record);
    });
  }

  paymentSet() {
    if (this.subject.anyPaymentsMade != 'true') {
      return [];
    }

    const presentPayments = this.subject.payments.filter((record) => {
      return !record.isEmpty();
    });

    return presentPayments.map((record) => {
      return new PaymentDTO(record);
    });
  }

  contactAttemptSet() {
    if (this.subject.anyFollowupContact != 'true') {
      return [];
    }

    const presentContactAttempts = this.subject.contactAttempts.filter((record) => {
      return !record.isEmpty();
    });

    return presentContactAttempts.map((record) => {
      return new ContactAttemptDTO(record);
    });
  }

  supportingDocumentSet() {
    return this.subject.supportingDocumentation.map((record) => {
      return record.documentID;
    })
  }

  debtorDetails() {
    return new DebtorDTO(this.subject.debtor);
  }

  toJSON() {
    return {
      "creditor": this.subject.creditor,
      "agreement": {
        "agreement_date": this.subject.agreedAtDate,
        "agreement_type": this.subject.agreementType == 'other' ? this.subject.agreementTypeText : this.subject.agreementType,
        "agreement_description": this.subject.agreementDescription,
      },
      "debtor": this.debtorDetails(),
      "invoices": this.invoiceSet(),
      "payments": this.paymentSet(),
      "contact_attempts": this.contactAttemptSet(),
      "documents": this.supportingDocumentSet(),
    };
  }
};

export default DebtDTO;
