import { VueEditor, Quill } from 'vue2-editor';

let BlockEmbed = Quill.import('blots/block/embed');

class PageBreakBlot extends BlockEmbed {
  static create() {
    let node = super.create();
    node.setAttribute('contenteditable', false);
    node.innerHTML = `
    <div>--- page break ---</div>
    `.replace(/\n/, '').replace(/\s+/, ' ').trim();
    return node;
  }

  static value(domNode) {
    return null;
  }
}
PageBreakBlot.blotName = 'page_break';
PageBreakBlot.className = 'page-break';
PageBreakBlot.tagName = 'div';

Quill.register(PageBreakBlot);
