function hasDirective(vnode, name) {
  return (vnode.data.directives.filter(function(e) { return e.name === name; }).length > 0);
}

const seedValue = {
  name: 'seed-value',
  bind(el, binding, vnode) {
    vnode.data.model.callback(binding.value);
    el.style = 'display: none;';
    el.remove();
  },
};

const setValue = {
  name: 'set-value',
  bind(el, binding, vnode) {
    const input = (typeof el.value !== 'undefined' ? el : el.querySelector('input, textarea, select, button'));

    input.value = binding.value;
    vnode.data.model.callback(binding.value);
  },
};

const defaultValue = {
  name: 'default-value',
  bind(el, binding, vnode) {
    const input = (typeof el.value !== 'undefined' ? el : el.querySelector('input, textarea, select, button'));

    if (!vnode.data.model.value && !hasDirective(vnode, 'set-value')) {
      input.value = binding.value;
      vnode.data.model.callback(binding.value);
    }
  },
};

const setValueMixin = {
  directives: { seedValue, setValue, defaultValue },
};

export { setValueMixin as default, seedValue, setValue, defaultValue, setValueMixin };
