const BaseDTO = require('./base').default;
const ContactDTO = require('./contact').default;

class DebtorDTO extends BaseDTO {
  contactSet() {
    return this.subject.contacts.map((record) => {
      return new ContactDTO(record);
    });
  }

  toJSON() {
    return {
      "business_type": (this.subject.isAnIndividual ? 'individual' : this.subject.entityType),
      "business_name": this.subject.businessName,
      "trading_name": (this.subject.tradingNameSameAsBusinessName ? this.subject.businessName : this.subject.tradingName),
      "abn": this.subject.abn,
      "acn": this.subject.acn,
      "incorporation_number": this.subject.incorporationNumber,
      "registration_number": this.subject.registrationNumber,
      "government_body_type": this.subject.governmentBodyType,
      "enabling_legislation": this.subject.enablingLegislation,
      "contact_details": this.contactSet(),
      "address_details": {
        "line1": this.subject.address.line1,
        "line2": this.subject.address.line2,
        "city": this.subject.address.suburb,
        "postcode": this.subject.address.postcode,
        "state": this.subject.address.state,
      },
      "full_name": this.subject.fullName,
    };
  }
};

export default DebtorDTO;
