import { VueEditor, Quill } from 'vue2-editor';

let BlockEmbed = Quill.import('blots/block/embed');

const placeholderTables = {
  standard_invoice: `
    <table>
      <thead>
        <tr>
          <th>Date of Invoice</th>
          <th>Invoice Number</th>
          <th>Amount Due</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="3">
            Placeholder: table containing of all invoice dates, numbers, and amounts
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="2" align="right">Total</th>
          <td>$0.00</td>
        </tr>
      </tfoot>
    </table>
  `,
  standard_payment: `
    <table>
      <thead>
        <tr>
          <th>Date of Payment</th>
          <th>Payment Method</th>
          <th>Reference Number</th>
          <th>Amount Paid</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="4">
            Placeholder: table containing of all payment dates, references, and amounts
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="3" align="right">Total</th>
          <td>$0.00</td>
        </tr>
      </tfoot>
    </table>
  `,
  standard_payment_schedule: `
    <table>
      <thead>
        <tr>
          <th>Due Date</th>
          <th>Amount Due</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="2">
            Placeholder: table containing all remaining payments in the payment schedule
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th align="right">Total</th>
          <td>$0.00</td>
        </tr>
      </tfoot>
    </table>
  `,
};

class TableBlot extends BlockEmbed {
  static create(tableid) {
    let node = super.create();
    node.dataset.tabletype = tableid;
    node.setAttribute('contenteditable', false);
    node.innerHTML = (placeholderTables[tableid] || '').replace(/\n/, '').replace(/\s+/, ' ').trim();
    return node;
  }

  static value(domNode) {
    return domNode.dataset.tabletype;
  }
}
TableBlot.blotName = 'placeholder_table';
TableBlot.className = 'placeholder-table';
TableBlot.tagName = 'div';

Quill.register(TableBlot);
