import Vue from 'vue/dist/vue.esm.js';
import { VueEditor, Quill } from 'vue2-editor';

const contentReady = require('../helpers/content_ready.js').default;

contentReady(() => {
  if (document.getElementById('content_editor')) {
    let editorContentElement = document.getElementById('content_editor_content');
    let editorContent = '';

    if (editorContentElement) {
      editorContent = editorContentElement.innerHTML.replace(/(?:\r\n|\r|\n)/g, '');
    }

    new Vue({
      el: '#content_editor',
      components: {
        'vue-editor': VueEditor,
      },
      data: {
        content: editorContent,
        editorSettings: {
          modules: {
            toolbar: {
              container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                ['bold', 'italic', 'underline', 'strike'],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                ['blockquote'],

                [{ 'align': [] }],

                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],

                [{ 'color': [] }, { 'background': [] }],

                ['clean'],
              ],
            },
          },
        },
      },
    });
  }
});
