const BaseDTO = require('./base').default;
const ContactDTO = require('./contact').default;

class CreditorDTO extends BaseDTO {
  contactSet() {
    return this.subject.contacts.map(function(record) {
      return new ContactDTO(record);
    });
  }

  toJSON() {
    return {
      business_type: this.subject.entityType,
      business_name: this.subject.businessName,
      trading_name: (this.subject.tradingNameSameAsBusinessName ? this.subject.businessName : this.subject.tradingName),
      abn: this.subject.abn,
      acn: this.subject.acn,
      full_name: this.subject.fullName,
      contact_details: this.contactSet(),
      address_details: {
        city: this.subject.address.suburb,
        postcode: this.subject.address.postcode,
        state: this.subject.address.state,
        street_address: this.subject.address.line1 + (this.subject.address.line2 ? `\n${this.subject.address.line2}` : ""),
      },
    };
  }
};

export default CreditorDTO;
