const BaseModel = require('./base').default;

class Address extends BaseModel {};

Address.attributeName('line1', 'Address Line 1');
Address.attributeName('line2', 'Address Line 2');

Address.validation('line1', { presence: true });
Address.validation('suburb', { presence: true });
Address.validation('state', { presence: true });
Address.validation('postcode', { presence: true, positive: true });

export default Address;
