function triggerEvent(name, meta) {
  let event;

  if (arguments.length > 2) {
    event = arguments;
  } else {
    event = { event: name };

    if (meta) {
      event = Object.assign(event, meta);
    }
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
}

export default triggerEvent;
