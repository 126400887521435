import Vue from 'vue/dist/vue.esm.js';

const contentReady = require('../helpers/content_ready.js').default;

contentReady(() => {
  if (document.getElementById('submit-debt-payment')) {
    new Vue({
      el: '#submit-debt-payment',
      data: {
        showNotification: false,
        notificationText: "Hello!",
        notificationType: "is-danger",
        paymentAmount: 0,
        paymentDate: new Date(),
        paymentMethod: "Bank Transfer",
        paymentReference: '',
        paymentNotes: '',
      },
      computed: {
        todaysDate() {
          return new Date();
        },
      },
      methods: {},
    });
  }
});
