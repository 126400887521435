import Vue from 'vue/dist/vue.esm.js';
import { VueEditor, Quill } from 'vue2-editor';
import Mention from 'quill-mention';

Quill.register('modules/mention', Mention);

require('../quill_modules/placeholder_block.js');

const contentReady = require('../helpers/content_ready.js').default;

contentReady(() => {
  const moneyPlaceholders = [
    { id: 'total_amount_due', value: 'Total Amount Due' }, // different to total invoices if debt collection clause adds fee on top
    { id: 'next_instalment_due_amount', value: 'Next Instalment Amount Due' },
  ];

  const placeholderValues = [
    { id: 'reference_number', value: 'Reference Number' }, // reference number for this debt
    { id: 'creditor_name', value: 'Creditor Name' }, // aka client name
    { id: 'debtor_name', value: 'Debtor Name' },
    { id: 'contact_name', value: 'Contact Name' },
    { id: 'due_date', value: 'Due Date' }, // date debt is due, duhhh
    { id: 'next_instalment_due_date', value: 'Next Instalment due Date' },
    { id: 'debt_link', value: 'Debt Link' }, // link to the debt
  ];

  if (document.getElementById('sms_editor')) {
    let smsTemplate = document.getElementById('sms_template');
    let editorContent = '';

    if (smsTemplate) {
      editorContent = smsTemplate.innerHTML.replace(/(?:\r\n|\r|\n)/g, '');
    }

    new Vue({
      el: '#sms_editor',
      components: {
        'vue-editor': VueEditor,
      },
      data: {
        content: editorContent,
        moneyPlaceholders: moneyPlaceholders,
        placeholderValues: placeholderValues,
        editorSettings: {
          modules: {
            toolbar: {
              container: [],
              handlers: {},
            },
            mention: {
              allowedChars: /^[A-Za-z\s0-9_]*$/,
              mentionDenotationChars: ["$", "!"],
              source: function (searchTerm, renderList, mentionChar) {
                let values;

                if (mentionChar === "$") {
                  values = moneyPlaceholders;
                } else {
                  values = placeholderValues;
                }

                if (searchTerm.length === 0) {
                  renderList(values, searchTerm);
                } else {
                  const matches = [];
                  for (let i = 0; i < values.length; i++) {
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                  }
                  renderList(matches, searchTerm);
                }
              },
            },
          },
        },
      },
      watch: {
        content(value) {
          // console.log(value);
        },
      },
      computed: {
      },
      methods: {
        previewContent() {
          console.log(this.content);
        },
      },
    });
  }
});
