const BaseDTO = require('./base').default;

class ContactDTO extends BaseDTO {
  toJSON() {
    return {
      "type": this.subject.contactType,
      "value": this.subject.value,
      "notes": this.subject.notes,
    };
  }
};

export default ContactDTO;
