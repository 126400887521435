const BaseModel = require('./base').default;
const Invoice = require('./invoice').default;

class Payment extends BaseModel {
  constructor(attributes) {
    super(attributes);
  }

  isEmpty() {
    return (!this.paymentDate && !this.paymentAmount && !this.invoice && !this.referenceId);
  }
};

Payment.attributeName('paymentAmount', 'Payment Amount');
Payment.attributeName('referenceId', 'Reference');

// Payment.hasOne('invoice', Invoice);

Payment.validation('paymentDate', { presence: true });
Payment.validation('paymentAmount', { presence: true, positive: true });

export default Payment;
