const electronicSignature = function() {
  // const signatureTemplate  = "<div class=\"electronic-signature-container\">";
  //       signatureTemplate += "  <canvas data-electronic-signature-canvas width='600' height='220' tabindex='0'></canvas>";
  //       signatureTemplate += "  <br />";
  //       signatureTemplate += "  <a href=\"#\" data-clear-electronic-signature>clear signature</a>";
  //       signatureTemplate += "</div>";

  let signatureTemplateInnerHTML  = "<canvas data-electronic-signature-canvas width='600' height='220' tabindex='0'></canvas>";
      signatureTemplateInnerHTML += "<br />";
      signatureTemplateInnerHTML += "<a href=\"#\" data-clear-electronic-signature>clear signature</a>";

  const signatureTemplate = document.createElement('div');

  signatureTemplate.classList.add('electronic-signature-container');
  signatureTemplate.innerHTML = signatureTemplateInnerHTML;

  let isSigning = false;

  const init = function() {
    if(!window.requestCanvasFrame) {
      window.requestCanvasFrame = function(callback) {
        return window.requestAnimationFrame ||
               window.webkitRequestAnimationFrame ||
               window.mozRequestAnimationFrame ||
               window.oRequestAnimationFrame ||
               window.msRequestAnimationFrame ||
               function (callback) { window.setTimeout(callback, 1000/60); };
      }();
    }

    document.querySelectorAll('[data-electronic-signature]').forEach(function(item) {
      initSignatureField(item);
    });
  }

  const initSignatureField = function(element) {
    const signatureContainer = signatureTemplate.cloneNode(true);
    const signatureCanvas    = signatureContainer.querySelector('[data-electronic-signature-canvas]');
    const signatureContext   = signatureCanvas.getContext('2d');

    let canvasSnapshot = null;
    let drawPoints     = [];

    const signatureUpdateLoop = function() {
      requestCanvasFrame(signatureUpdateLoop);
      if(isSigning) {
        renderSignatureCanvas(false);
      }
    }

    const renderSignatureCanvas = function(active) {
      if(drawPoints.length == 0) {
        return;
      }

      const smooth = true;

      signatureContext.lineWidth   = 3;
      signatureContext.lineJoin    = 'round';
      signatureContext.lineCap     = 'round';
      signatureContext.strokeStyle = '#000000';

      if (!active) {
        signatureContext.lineWidth   = 2;
        signatureContext.strokeStyle = '#666666';
      }

      signatureContext.beginPath();
      if(smooth && drawPoints.length < 3) {
        const b = drawPoints[0];

        signatureContext.arc(b.x, b.y, signatureContext.lineWidth / 2, 0, Math.PI * 2, !0);
        signatureContext.fill();
      }
      else if(smooth) {
        signatureContext.moveTo(drawPoints[0].x, drawPoints[0].y);

        let i;

        for(i = 1; i < drawPoints.length - 2; i++) {
          const c = (drawPoints[i].x + drawPoints[i + 1].x) / 2;
          const d = (drawPoints[i].y + drawPoints[i + 1].y) / 2;

          signatureContext.quadraticCurveTo(drawPoints[i].x, drawPoints[i].y, c, d);
        }

        signatureContext.quadraticCurveTo(
          drawPoints[i].x,
          drawPoints[i].y,
          drawPoints[i + 1].x,
          drawPoints[i + 1].y
        );
      }
      else {
        const currentDrawPos = drawPoints[drawPoints.length - 1];
        const lastDrawPos    = drawPoints[drawPoints.length - 2] || currentDrawPos;

        signatureContext.moveTo(lastDrawPos.x, lastDrawPos.y);
        signatureContext.lineTo(currentDrawPos.x, currentDrawPos.y);
      }
      signatureContext.stroke();
      signatureContext.closePath();
    }

    const renderSmoothSignatureCanvas = function() {
      if(canvasSnapshot) {
        const img = new Image;
        img.onload = function() {
          clearSignatureCanvas(signatureCanvas, signatureContext);
          signatureContext.drawImage(img, 0, 0);
          renderSignatureCanvas(true);
          canvasSnapshot = null;
          drawPoints = [];
          element.value = getSignatureCanvasDataURI(signatureCanvas);
        };
        img.src = canvasSnapshot;
      }
      else {
        renderSignatureCanvas(true);
      }
    }

    const drawDownHandler = function(e) {
      canvasSnapshot = getSignatureCanvasDataURI(signatureCanvas);
      isSigning = true;
      drawPoints.push(getDrawPosition(signatureCanvas, e));
      e.preventDefault();
    }

    const drawUpHandler = function(e) {
      if(!isSigning) {
        return;
      }

      isSigning = false;

      if(drawPoints.length > 0) {
        renderSmoothSignatureCanvas();
      }

      e.preventDefault();
    }

    const drawMoveHandler = function(e) {
      e.preventDefault();
      if(!isSigning) {
        return;
      }
      drawPoints.push(getDrawPosition(signatureCanvas, e));
    }

    signatureCanvas.addEventListener('mousedown', drawDownHandler)
    signatureCanvas.addEventListener('mousemove', drawMoveHandler);

    document.addEventListener('mouseup', drawUpHandler);

    signatureCanvas.addEventListener('touchstart', drawDownHandler);
    signatureCanvas.addEventListener('touchmove', drawMoveHandler);
    document.addEventListener('touchend', drawUpHandler);

    element.style.display = 'none';
    element.insertAdjacentElement('afterend', signatureContainer);

    signatureContainer.querySelector('[data-clear-electronic-signature]').addEventListener('click', function(event) {
      canvasSnapshot = null;
      drawPoints = [];
      element.value = '';
      clearSignatureCanvas(signatureCanvas, signatureContext);
      event.preventDefault();
    });

    renderSignatureBackdrop(signatureCanvas, signatureContext);
    signatureUpdateLoop();
  }

  const getDrawPosition = function(element, event) {
    var canvasBounds = (element.getBoundingClientRect ? element.getBoundingClientRect() : element[0].getBoundingClientRect());
    var drawEvent    = (event.targetTouches && event.targetTouches[0] ? event.targetTouches[0] : event);
    var drawX        = drawEvent.clientX - canvasBounds.left;
    var drawY        = drawEvent.clientY - canvasBounds.top;

    return { x: drawX, y: drawY };
  }

  const getSignatureCanvasDataURI = function(signatureCanvas) {
    return (signatureCanvas.toDataURL ? signatureCanvas.toDataURL() : signatureCanvas[0].toDataURL());
  }

  const renderSignatureBackdrop = function(signatureCanvas, signatureContext) {
    clearSignatureCanvas(signatureCanvas, signatureContext);

    signatureContext.strokeStyle = '#cccccc';
    signatureContext.lineWidth = 3;

    signatureContext.beginPath();
    signatureContext.moveTo(25, 195);
    signatureContext.lineTo(575, 195);
    signatureContext.stroke();
    signatureContext.closePath();

    signatureContext.strokeStyle = '#999999';
    signatureContext.lineWidth = 3;

    signatureContext.beginPath();
    signatureContext.moveTo(25, 175);
    signatureContext.lineTo(35, 185);
    signatureContext.stroke();
    signatureContext.closePath();

    signatureContext.beginPath();
    signatureContext.moveTo(35, 175);
    signatureContext.lineTo(25, 185);
    signatureContext.stroke();
    signatureContext.closePath();

    signatureCanvas.style['background-image'] = 'url('+getSignatureCanvasDataURI(signatureCanvas)+')';

    clearSignatureCanvas(signatureCanvas, signatureContext);
  }

  const clearSignatureCanvas = function(signatureCanvas, signatureContext) {
    signatureContext.clearRect(0, 0, parseInt(signatureCanvas.width), parseInt(signatureCanvas.height));
  }

  return {
    init: init,
    initSignatureField: initSignatureField,
  };
}();

export default electronicSignature;
