const BaseDTO = require('./base').default;

class PaymentDTO extends BaseDTO {
  toJSON() {
    return {
      "payment_date": this.subject.paymentDate,
      "reference_id": this.subject.referenceId,
      "payment_method": this.subject.paymentMethod,
      "payment_amount": this.subject.paymentAmount.replace(/[^0-9.]/g, ''),
      "invoice_correlation_id": this.subject.invoice,
    };
  }
};

export default PaymentDTO;
