const filter = function() {
  function init() {
    document.querySelectorAll('[data-filter-target]').forEach(function(item) {
      initFilter(item);
    });
  }

  function initFilter(element) {
    var target = document.querySelector(element.getAttribute('data-filter-target'));
    var filterAttribute = 'data-' + element.getAttribute('data-filter-attribute') || 'filtered-by';
    var options = [];

    if (!target) { return; }

    target.childNodes.forEach(function(item) {
      if (!item.tagName || item.tagName.toUpperCase() !== 'OPTION') { return; }

      options.push(item.cloneNode(true));
    });

    function filterSelect() {
      var value = element.value;

      target.innerHTML = '';

      options.forEach(function(item) {
        if (!item.getAttribute) { return; }

        var itemValue = item.getAttribute(filterAttribute);

        if (typeof itemValue !== 'undefined' && itemValue !== null && itemValue != value) { return; }

        target.appendChild(item.cloneNode(true));
      });
    }

    element.addEventListener('change', filterSelect);
    filterSelect();
  }

  return {
    init: init,
    initFilter: initFilter,
  };
}();

export default filter;
