/*
Possible attributes:
- acn
- enabling_legislation
- incorporation_number
- registration_number
*/

const entityModelAttributes = {
  sole_trader: {
    //
  },
  proprietary_company: {
    acn: true,
  },
  public_company: {
    acn: true,
  },
  incorporated_association: {
    incorporation_number: true,
  },
  unincorporated_association: {
    //
  },
  registered_cooperative: {
    registration_number: true,
  },
  partnership: {
    //
  },
  government_body: {
    enabling_legislation: true,
  },
};

const isAttributeRequired = function(entity, attribute) {
  return (entityModelAttributes[entity] || {})[attribute] || false;
};

const elementBindingHandler = function(el, binding, vnode) {
  if (!isAttributeRequired(binding.value, binding.arg)) {
    el.style.display = 'none';
  }
  else {
    el.style.display = '';
  }
};

const entityModelDirective = {
  name: 'entity-attr',
  bind: elementBindingHandler,
  update: elementBindingHandler,
};

const entityModelMixin = {
  directives: { 'entity-attr': entityModelDirective },
  data() { return { entityModelAttributes }; },
};

export { entityModelMixin as default, entityModelMixin, entityModelAttributes };
