const BaseModel = require('./base').default;
const Contact = require('./contact').default;
const Address = require('./address').default;

class BusinessEntity extends BaseModel {
  constructor(attributes) {
    super(attributes);

    this.address = this.address || {};
    this.contacts = this.contacts || [];

    this.contacts = this.contacts.filter(function(record) {
      return !!Object.keys(record).length;
    });

    if (this.contacts.length == 0) {
      this.contacts.push(new Contact({ contactType: 'email' }));
      this.contacts.push(new Contact({ contactType: 'mobile' }))
    }
  }
};

function unlessAnIndividual(model) {
  return model.isAnIndividual;
}

BusinessEntity.attributeName('businessName', 'Entity Name');
BusinessEntity.attributeName('tradingName', 'Business/Trading Name');
BusinessEntity.attributeName('abn', 'ABN');
BusinessEntity.attributeName('acn', 'ACN');

BusinessEntity.hasMany('contacts', Contact);
BusinessEntity.hasOne('address', Address);

BusinessEntity.validation('entityType', { presence: true, unless: unlessAnIndividual });
BusinessEntity.validation('businessName', { presence: true, unless: unlessAnIndividual });
BusinessEntity.validation('tradingName', { presence: true, unless: (model) => { return model.tradingNameSameAsBusinessName || model.isAnIndividual; } });
BusinessEntity.validation('acn', { presence: true, if: (model) => { return ['proprietary_company', 'public_company'].indexOf(model.entityType) >= 0; }, unless: unlessAnIndividual });
BusinessEntity.validation('incorporationNumber', { presence: true, if: (model) => { return ['incorporated_association'].indexOf(model.entityType) >= 0; }, unless: unlessAnIndividual });
BusinessEntity.validation('registrationNumber', { presence: true, if: (model) => { return ['registered_cooperative'].indexOf(model.entityType) >= 0; }, unless: unlessAnIndividual });
BusinessEntity.validation('governmentBodyType', { presence: true, if: (model) => { return ['government_body'].indexOf(model.entityType) >= 0; }, unless: unlessAnIndividual });
BusinessEntity.validation('enablingLegislation', { presence: true, if: (model) => { return ['government_body'].indexOf(model.entityType) >= 0; }, unless: unlessAnIndividual });

BusinessEntity.validation('contacts', { callback: (model) => {
  let contactCount = 0;

  model.contacts.forEach((contact) => {
    contactCount += contact.validate() ? 1 : 0;
  });

  if (contactCount == 0) {
    return 'must include at least one valid contact.';
  }
} });

export default BusinessEntity;
