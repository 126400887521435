import Vue from 'vue/dist/vue.esm.js';
import Buefy from 'buefy';

Vue.use(Buefy, {
  defaultIconPack: 'fas',
});

const inputMasksMixin = require('./mixins/input_masks.js').default;
const formattersMixin = require('./mixins/formatters.js').default;
const generatorsMixin = require('./mixins/generators.js').default;
const entityModelsMixin = require('./mixins/entity_models.js').default;
const setValueMixin = require('./mixins/set_value.js').default;

Vue.mixin(inputMasksMixin);
Vue.mixin(formattersMixin);
Vue.mixin(generatorsMixin);
Vue.mixin(entityModelsMixin);
Vue.mixin(setValueMixin);
