function camelToUnderscore(key) {
  return key.replace( /([A-Z])/g, "_$1").toLowerCase();
}

class BaseDTO {
  constructor(subject) {
    this.subject = subject;
  }

  underscored() {
    const newObject = {};
    for (const key in this.subject) {
      newObject[camelToUnderscore(key)] = this.subject[key];
    }
    return new this.constructor(newObject);
  }

  toJSON() {
    return null;
  }
};

export default BaseDTO;
