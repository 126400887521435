import Vue from 'vue/dist/vue.esm.js';

const contentReady = require('../helpers/content_ready.js').default;
const triggerEvent = require('../helpers/trigger_event.js').default;

const Debtor = require('../models/debtor').default;

contentReady(() => {
  if (document.getElementById('edit_debtor')) {
    new Vue({
      el: '#edit_debtor',
      data: {
        debtor: new Debtor,
      },
    });
  }
});
