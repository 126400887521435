import Vue from 'vue/dist/vue.esm.js';
import { VueEditor, Quill } from 'vue2-editor';
import Mention from 'quill-mention';

Quill.register('modules/mention', Mention);

require('../quill_modules/pagebreak_block.js');
require('../quill_modules/placeholder_block.js');

const contentReady = require('../helpers/content_ready.js').default;

contentReady(() => {
  const moneyPlaceholders = [
    { id: 'first_invoice_amount', value: 'First Invoice Amount' },
    { id: 'last_invoice_amount', value: 'Last Invoice Amount' },
    { id: 'total_invoice_amount', value: 'Total Invoice Amount' },
    { id: 'invoice_amount_list', value: 'Invoice Amount List' },
    { id: 'total_amount_due', value: 'Total Amount Due' }, // different to total invoices if debt collection clause adds fee on top
    { id: 'next_instalment_due_amount', value: 'Next Instalment Amount Due' },
  ];

  const placeholderValues = [
    { id: 'reference_number', value: 'Reference Number' }, // reference number for this debt
    { id: 'document_date', value: 'Document Date' }, // when the document (demand letter) is generated
    { id: 'creditor_name', value: 'Creditor Name' }, // aka client name
    { id: 'debtor_name', value: 'Debtor Name' },
    { id: 'contact_name', value: 'Contact Name' },
    { id: 'claim_manager_name', value: 'Claim Manager Name' },
    { id: 'claim_manager_phone', value: 'Claim Manager Phone' },
    { id: 'claim_manager_signature', value: 'Claim Manager Signature' },
    { id: 'agreement_date', value: 'Agreement Date' },
    { id: 'agreement_type', value: 'Agreement Type' }, // service, product, other
    { id: 'agreement_details', value: 'Agreement Details' }, // short summary of services/product/other?
    { id: 'first_invoice_date', value: 'First Invoice Date' },
    { id: 'last_invoice_date', value: 'Last Invoice Date' },
    { id: 'invoice_date_range', value: 'Invoice Date Range' },
    { id: 'invoice_date_list', value: 'Invoice Date List' },
    { id: 'first_invoice_number', value: 'First Invoice Number' },
    { id: 'last_invoice_number', value: 'Last Invoice Number' },
    { id: 'invoice_number_list', value: 'Invoice Number List' },
    { id: 'first_contact_date', value: 'First Contact Date' },
    { id: 'last_contact_date', value: 'Last Contact Date' },
    { id: 'contact_date_range', value: 'Contact Date Range' },
    { id: 'contact_date_list', value: 'Contact Date List' },
    { id: 'first_contact_type', value: 'First Contact Type' },
    { id: 'last_contact_type', value: 'Last Contact Type' },
    { id: 'contact_type_list', value: 'Contact Type List' }, // unique? eg. no "email, email, email, email, phone"
    { id: 'due_date', value: 'Due Date' }, // date debt is due, duhhh
    { id: 'next_instalment_due_date', value: 'Next Instalment due Date' },
    { id: 'debt_link', value: 'Debt Link' }, // link to the debt
    { id: 'site_signature', value: 'Site Signature' },
  ];

  const placeholderTables = {
    standard_invoice: { text: 'Invoice Table' },
    standard_payment: { text: 'Payment Table' },
    standard_payment_schedule: { text: 'Payment Schedule Table' },
  };

  if (document.getElementById('letter_editor')) {
    let letterTemplate = document.getElementById('letter_template');
    let editorContent = '';

    if (letterTemplate) {
      editorContent = letterTemplate.innerHTML.replace(/(?:\r\n|\r|\n)/g, '');
    }

    new Vue({
      el: '#letter_editor',
      components: {
        'vue-editor': VueEditor,
      },
      data: {
        content: editorContent,
        moneyPlaceholders: moneyPlaceholders,
        placeholderValues: placeholderValues,
        editorSettings: {
          modules: {
            toolbar: {
              container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                ['bold', 'italic', 'underline', 'strike'],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                ['blockquote'],

                [{ 'align': [] }],

                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],

                [{ 'color': [] }, { 'background': [] }],

                ['page_break'], // <--- custom toolbar icon thing

                [{ 'placeholder_table': Object.keys(placeholderTables) }], // <--- custom toolbar icon thing

                ['clean'],
              ],
              handlers: {
                // Custom toolbar icon handler -- just a garbo placeholder table for now
                'page_break': function() {
                  let range = this.quill.getSelection(true);
                  this.quill.insertText(range.index, '\n', Quill.sources.USER);
                  this.quill.insertEmbed(range.index + 1, 'page_break', null, Quill.sources.USER);
                  this.quill.setSelection(range.index + 2, Quill.sources.SILENT);
                },

                // Custom toolbar icon handler -- just a garbo placeholder table for now
                'placeholder_table': function(index) {
                  let range = this.quill.getSelection(true);
                  this.quill.insertText(range.index, '\n', Quill.sources.USER);
                  this.quill.insertEmbed(range.index + 1, 'placeholder_table', index, Quill.sources.USER);
                  this.quill.setSelection(range.index + 2, Quill.sources.SILENT);
                },
              },
            },
            mention: {
              allowedChars: /^[A-Za-z\s0-9_]*$/,
              mentionDenotationChars: ["$", "!"],
              source: function (searchTerm, renderList, mentionChar) {
                let values;

                if (mentionChar === "$") {
                  values = moneyPlaceholders;
                } else {
                  values = placeholderValues;
                }

                if (searchTerm.length === 0) {
                  renderList(values, searchTerm);
                } else {
                  const matches = [];
                  for (let i = 0; i < values.length; i++) {
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                  }
                  renderList(matches, searchTerm);
                }
              },
            },
          },
        },
      },
      watch: {
        content(value) {
          // console.log(value);
        },
      },
      computed: {
      },
      methods: {
        previewContent() {
          console.log(this.content);
        },
      },
    });

    Object.keys(placeholderTables).forEach((pt) => {
      const placeholderTable = placeholderTables[pt];
      const selector = document.querySelector('.ql-picker-item[data-value="' + pt + '"]');

      if (!selector) { return; }

      selector.innerHTML = placeholderTable.text;
    });
  }
});
