import Vue from 'vue/dist/vue.esm.js';

const contentReady = require('../helpers/content_ready.js').default;
const triggerEvent = require('../helpers/trigger_event.js').default;

const Creditor = require('../models/creditor').default;
const CreditorDTO = require('../dtos/creditor').default;

contentReady(() => {
  function postDetails(payload, url, success) {
    const request = new XMLHttpRequest();

    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

    request.onload = function() {
      // All good
      if (request.status >= 200 && request.status < 400) {
        const response = JSON.parse(request.responseText);

        console.log('Great success:', response);

        if (typeof success === 'function') {
          success();
        }
      }
      else {
        console.log('Failed trying to do things...');
      }
    };

    // Connection error
    request.onerror = function() {
      console.log('Error!');
    };

    request.send(JSON.stringify(payload));
  }

  if (document.getElementById('signup_wizard')) {
    new Vue({
      el: '#signup_wizard',
      data: {
        businessName: '',
        businessABN: '',
        fullName: '',
        email: '',
        phone: '',
        password: '',
        authOTP: '',
        creditor: new Creditor,
      },
      methods: {
        nextClicked(currentPage) {
          if (currentPage.slot == 'your_account') {
            postDetails({ email: this.email, password: this.password }, '/signup', () => { triggerEvent('signupSuccess'); });

            return true;
          }
          else if (currentPage.slot == 'your_details') {
            postDetails(new CreditorDTO(this.creditor).toJSON(), '/creditor', () => { window.location.href = '/debt/new'; });

            return true;
          };

          return true;
        },
      },
    });
  }
});
